// $(document).on('keyup', '.otp-digit', function (e) {
//   // below condition dosen't written for checking keycode. it is written to prevent tab change event
//   if ((e.keyCode <= 105 && e.keyCode >= 96) || e.keyCode <= 57 && e.keyCode >= 48) {
//     var next_digit_box = $(this).data('next-digit-box');
//     $('.otp-digit-' + next_digit_box).trigger('focus');
//     if (next_digit_box == "fifth") {
//       var is_otp_length_proper = check_otp_length();
//       if (is_otp_length_proper) {
//         $.ajax({
//           url: '/check_otp',
//           type: "put",
//           data: {
//             otp: get_otp(),
//             phone_number: $('.user-phone-number').val(),
//             country_code: $('.country-code').val(),
//             page: $('.page_value').val()
//           },
//           dataType: "script"
//         });
//       }
//     }
//   } else if (e.keyCode == 8) {
//     var prev_digit_box = $(this).data('prev-digit-box');
//     $('.otp-digit-' + prev_digit_box).trigger('focus');
//   }
// });

$(document).on('keyup', '.otp-digit-verify', function (e) {
  // below condition dosen't written for checking keycode. it is written to prevent tab change event
  if ((e.keyCode <= 105 && e.keyCode >= 96) || e.keyCode <= 57 && e.keyCode >= 48) {
    var next_digit_box = $(this).data('next-digit-box');
    $('.otp-digit-' + next_digit_box).trigger('focus');
    if (next_digit_box == "fifth") {
      if (check_otp_length()) {
        $.ajax({
          url: '/check_otp',
          type: "put",
          data: {
            otp: get_otp(),
            country_code: $('.country-code').val(),
            phone_number: $('.user-phone-number').val(),
            new_email: $('.new_email').val(),
            crm_user_id: $('.crm-user').val()
          },
          dataType: "script"
        });
      }
    }
  } else if (e.keyCode == 8) {
    var prev_digit_box = $(this).data('prev-digit-box');
    $('.otp-digit-' + prev_digit_box).trigger('focus');
  }
});

$(document).on('keypress', '.otp-field', function(event){
  var key = event.keyCode;
  return ($(this).val() == '' && key >= 48 && key <= 57)
});

// $(document).on('keyup', '.verify-otp-digit', function (e) {
//   // below condition dosen't written for checking keycode. it is written to prevent tab change event
//   if (e.keyCode <= 105 && e.keyCode >= 96){
//     var next_digit_box = $(this).data('next-digit-box');
//     $('.otp-digit-' + next_digit_box).trigger('focus');
//     if (next_digit_box == "fifth") {
//       var is_otp_length_proper = check_otp_length();
//       if (is_otp_length_proper) {
//         $.ajax({
//           url: '/verify_new_mobile',
//           type: "put",
//           data: {
//             otp: get_otp(),
//             phone_number: $('.user-phone-number').val(),
//             new_email: $('.new_email').val()
//           },
//           dataType: "script"
//         });
//       }
//     }
//   }
// });

$(document).ready(function () {
  $('.otp-digit-one').trigger('focus');
});

function check_otp_length() {
  var otp = get_otp();
  if (otp.length != 4) {
    return false
  } else {
    return true
  }
}

function get_otp() {
  var first_digit = $('.otp-digit-one').val()
  var second_digit = $('.otp-digit-two').val()
  var third_digit = $('.otp-digit-three').val()
  var fourth_digit = $('.otp-digit-fourth').val()

  return "" + first_digit + second_digit + third_digit + fourth_digit
}

$(document).on('keyup keypress change', '.phone-number', function (e) {
  phone_number = /^(1\s?)?((\([0-9]{3}\)-)|[0-9]{3})[\s\-]?[\0-9]{3}-[\s\-]?[0-9]{4}$/
  space_regex = /\(?\d{3}\)?[ ]\d{3}?[ ]\d{4}/
  dash_regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
  var curchr = this.value.length;
  var curval = $(this).val();
  if (curchr == 3 && curval.indexOf("(") <= -1) {
    if (e.which != 8) {
      $(this).val("(" + curval + ")" + " ");
    }
  } else if (curchr == 4 && curval.indexOf("(") > -1) {
    if (e.which != 8) {
      $(this).val(curval + ")-");
    }
  } else if (curchr == 5 && curval.indexOf(")") > -1) {
    if (e.which != 8) {
      $(this).val(curval + "-");
    }
  } else if (curchr == 9) {
    if (e.which != 8) {
      $(this).val(curval + "-");
    }
    $(this).attr('maxlength', '14');
  } else if (curchr == 10 && curval.indexOf("(") != 0) {
    if (e.which != 8) {
      $(this).val("(" + curval.slice(0, 3) + ")" + " " + curval.slice(3, 6) + "-" + curval.slice(6, 10))
    }
    $(this).attr('maxlength', '14');
    $(this).parsley().validate();
  }
});