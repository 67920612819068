import consumer from "./consumer"
consumer.subscriptions.create("PrinterQueueV4Channel", {
  connected() {
    console.log("-------connected to printer queue v4------------");
  },

  disconnected() {
    console.log("-------disconnected to printer queue v4------------");
    // Called when the subscription has been terminated by the server
  },
});