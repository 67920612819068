// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
global.toastr = require("toastr")

require("jquery");
require("bootstrap");
require("packs/bootstrap-datetimepicker.min")
require("@fortawesome/fontawesome-free/css/all.css");
require("packs/sidebar.js");
require("packs/custom.js");
require('packs/otp');

require("packs/app.min.js");
require("@nathanvda/cocoon");
import "packs/jquery.multi-select.js";
import "select2";
import "select2/dist/css/select2.css";

require('toastr');

const images = require.context("../images", true);
import Sortable from "sortablejs";
import "chartkick/chart.js"
require("chart.js")
require("timepicker");
import "timepicker/jquery.timepicker.css";
require('parsleyjs');
require('fabric');
require('jquery.blockUI.min.js')
import 'bootstrap-datepicker';
import "../stylesheets/application";
require("jquery-ui/ui/widgets/draggable")
require("packs/crm_cart.js");
require("packs/owl.carousel.min.js")

window.$ = $;

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  const sortingElement = document.getElementById("sortable-elem");

  // get calls only if div with id sortable-elem and onEnd function exists
  if (sortingElement && (typeof updateSortableOnEnd === "function")) {
    Sortable.create(sortingElement, {
      handle: ".sorting-handler",
      onEnd: updateSortableOnEnd,
    });
  }
});

$(document).on('change', '.selectAllModule', function(){
  var is_checked = $(this).is(':checked')
  var check_class = '.'+ $(this).data('id')
  $(check_class).prop('checked', is_checked)
})

Rails.start();
Turbolinks.start();
ActiveStorage.start();
