import consumer from "./consumer"
consumer.subscriptions.create("PrinterQueueChannel", {
  connected() {
    console.log("-------connected");
  },

  disconnected() {
    console.log("-------disconnected--- ");
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.printer_queue == 'printer_list') {
      console.log("passing..." + data)
      $.ajax({
        url: '/api/v1/printer_queues',
        type: "GET",
        data: {
          id: data.location_id
        },
        success: function () {
          console.log("PrinterQueue fetch successfully....");
        }
      });
    }
  }
});