import consumer from "./consumer"
consumer.subscriptions.create("PrinterQueueDataChannel", {
  connected() {
    console.log("-------connected to printer queue data channel------------");
  },

  disconnected() {
    console.log("-------disconnected to printer data channel-----------");
    // Called when the subscription has been terminated by the server
  },
});