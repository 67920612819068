import consumer from "./consumer"
consumer.subscriptions.create("SyncChannel", {
  connected() {
    console.log("-------connected to sync channel");
  },

  disconnected() {
    console.log("-------disconnected--- from sync channel");
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log("-------recevied sync--- ", data);
  }
});