import consumer from "./consumer"
consumer.subscriptions.create("KitchenChannel", {
  connected() {
    console.log("-------connected");
  },

  disconnected() {
    console.log("-------disconnected--- ");
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var currentLocationId = parseInt($('#current_location_id').val());
    console.log("-------recevied--- ", data);
    console.log("test" + data.location_id === currentLocationId)
    if (data.location_id === currentLocationId) {
      if (data.flag == 'refresh_page') {
        $('.refresh_kitchen').click();
      }

      if (data.flag == 'new_order') {
        console.log("new order----")
        var kitchen_station_id = $('#kitchen_station_id').length == 0 ? 0 : $('#kitchen_station_id').val();
        $.ajax({
          url: '/kitchen_displays/' + data.order_id + '/render_new_order',
          type: "GET",
          data: {
            kitchen_station_id: kitchen_station_id
          },
          success: function () {
            console.log(data.order_id)
            console.log($('.order_card_' + data.order_id).length >= 1)
            if ($('.order_card_' + data.order_id).length >= 1) {
              $('#notifyOrder')[0].play();
            }
          }
        });
      }
      if (data.flag == 'order_completed') {
        if ($('.order_card_' + data.order_id).length >= 1) {
          if ($('#isLiquidDesign').val() == 'yes') {
            $('#orderListing').masonry('remove', $('.order_card_' + data.order_id));
          } else {
            $('.order_card_' + data.order_id + '_' + data.kitchen_station_id).remove();
          }
        }
        if (location.href.includes("default_station_list")) {
          location.reload();
        }
      }
      if (data.flag == 'order_item_completed') {
        $('#item_' + data.order_item_id).prop('checked', data.is_item_completed);
        $('#item_' + data.order_item_id).data('quantity', data.quantity_for_update);
        $.each($('#order_items_' + data.order_item_id).find('.strikeItem'), function (index, element) {
          if (data.is_item_completed) {
            $(element).html('<strike>' + $(element).html() + '</strike>');
          } else {
            $(element).html($(element).html().replace("<strike>", '').replace("</strike>", ''));
          }
        })
        if (location.href.includes("default_station_list")) {
          location.reload();
        }
      }
      if (data.flag == 'payment_status') {
        $('.orderPayment_' + data.order_id).html(data.payment_status);
      }
      setTimeout(function () {
        $('.orderCount').html($.unique($.map($('.OrderCard'), function (element, index) {
          return $(element).data('id')
        })).length + ' Open');
      }, 2000);
    }
    if (data.printer_queue == 'printer_list') {
      console.log("passing..." + data)
      $.ajax({
        url: '/api/v1/printer_queues',
        type: "GET",
        data: {
          id: data.location_id
        },
        success: function () {
          console.log("PrinterQueue fetch successfully....");
        }
      });
    }
  }
});