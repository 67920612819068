import consumer from "./consumer"
consumer.subscriptions.create("PrinterQueueV3Channel", {
  connected() {
    console.log("-------connected to printer queue v3------------");
  },

  disconnected() {
    console.log("-------disconnected to printer queue v3------------");
    // Called when the subscription has been terminated by the server
  },
});